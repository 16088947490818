// Customizable Area Start
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import React from "react";
import { toast } from "react-toastify";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { conditionSort } from "../../../components/src/Helper";


export const configJSON = require("./config");

const initialValueForRequestedDocuments = [
  {
    id: 1,
    title: "Lorem Ipsum",
    file: null,
  }
];

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};

interface ValidClientResponse {
  data: {
    id: string,
    type: string,
    attributes: {
      activated: boolean,
      country_code: string | null,
      email: string,
      first_name: string,
      full_phone_number: string,
      last_name: string,
      phone_number: null | string,
      type: string,
      created_at: string,
      updated_at: string,
      device_id: null | string,
      unique_auth_id: string,
      date_of_birth: null | string,
      gender: null | string,
      bio: string,
      preffered_language: null | string,
      social_media_link: null | string,
      user_type: null | string,
      location: null | string,
      role_id: null | string,
      role: null | string,
      number_of_acquisition_closed: null | string,
      projected_annual_acquisitions: null | string,
      accredited_buyer: null | string,
      country: null | string,
      city: null | string,
      profile_percent: null | string,
      is_member: boolean,
      total_no_of_access: number,
      remaining_access: number,
      image: {
        id: number,
        url: string
      },
      document: null | string
    }
  }
};

interface InvalidResponse {
  error: string;
};

interface Documents {
  title: string,
  url: string,
  id: number
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  activeStep: number;
  handleActiveStepOnChange?: () => void;
  myAcquisitionType?: boolean;
  learnmoreList?:{title:string,description:string,url:string, arabic_title: string, arabic_description: string}[];
  selectedName?:string;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  openLearnMoreDialog: boolean;
  selectedLearnMoreContent: {
    url: string;
    title: string;
    description: string;
    arabic_title: string;
    arabic_description: string;
  };
  terminateAccess:boolean;
  stepActionTab: string;
  accessRequestId: number | null;
  dealId:number;
  profileId: string;
  chatId: string;
  userType:string;
  profileName:string;
  description:string;
  profileImage:string;
  learnmoreList:{title:string,description:string,url:string, arabic_title: string, arabic_description: string}[];
  displayChat: boolean,
  documentsSharedByMe:{title:string,url:string,id:number}[];
  documentsSharedWithMe: {title:string, url:string,id:number}[];
  unreadCount: number,
  // Document Tab
  openUploadDocumentDialog: boolean;
  uploadDocumentFile: any;
  // Action Tab 2
  isLOIDocumentUploaded: boolean;
  isLOIReviewChecked: boolean;
  isLOIAccepted: boolean;
  loiDocument: any;
  loiDocumentId:null | string;
  loiDocumentLink: string;
  loiStatus : boolean;
  loiStatusSend:boolean;
  isReviewDocuments: boolean;
  // Action Tab 3
  requestedDocumentsList: { id: number; title: string; file: any }[];
  isSalePurchaseAgreementUploaded: boolean;
  isSalePurchaseAgreementDone: boolean;
  requestedDocumentsForSeller: string[];
  reviedDocuments : {id:number, document_title:string,url:string}[];
  salesPurchaseDocument:any;
  dueDiligeneId: number;
  salePurchaseAgremntId:number;
  salesPurchaseAgeementUrl:string;
  keyWords:{title:string, arabic_title:string,description:string,arabic_description:string}[] | null;
  tipsVideos:{title:string,arabic_title:string,url:string,link:string}[] | null;
  uploadDocumentTitle:string;
  isRequestedDocsAcc:boolean;
  isReviewDocsAcc: boolean;
  isSalePurchaseDocAcc:boolean;
  isDocumentSent:boolean;
  isDocumentsRevied:boolean;
  isSalePurchaseDocSent:boolean;
  // Action Tab 4
  isStepFourDone: boolean;
  profileBoi:string;
  listingName:string;
  dealDetails: any;
  language: string;
  pageText: {
    areYouSure: string;
retBuyer: string;
terSeller: string;
yias: string;
ntmb: string;
    congrat: string;
    acquiring: string;
    selling: string;
    yourSu: string;
    inte: string;
    share: string;
    wn: string;
    got: string;
    exploreMarket: string;
    becomeSeller: string;
    intrestedToSell: string;
    buyOther: string;
    add: string;
    intr: string;
    become: string;
    sys: string;
great: string;
hasThe: string;
no: string;
hereNext: string;
checkTerms: string;
makeSure: string;
use: string;
safe: string;
signDocs: string;
get: string;
consult: string;
have: string;
paid: string;
once: string;
needHelp: string;
resourceCenter: string;
orGetItTouchWith: string;
support: string;
    listing: string;
    plSign: string;
    letterOfIntent: string;
    spaSent: string;
    iHaveRead: string;
    construct: string;
    loiSent: string;
    underReview: string;
loiProceed: string;
review: string;
proceed: string;
stageComplete: string;
title: string;
docReqSuc: string;
moveToD: string;
  evaluateTheCompany: string;
  viewProfile: string;
  handlingProposals: string;
  makeAnOffer: string;
transactionAndConclusion: string;
dueDiligence: string;
  undergoingDueDiligence: string;
  facilitatePayment: string;
  finalizeAcquisition: string;
  finalized: string;
  moveToLastStage: string;
  needHelpText: string;
  contactSupport: string;
  buyers: string;
  autoApprove: string;
  accepted: string;
  rejected: string;
  pending: string;
  noData: string;
  stage: string;
  accept: string;
  reject: string;
  videos: string;
keywords: string;
withMe: string;
  myMe: string;
  uploadDocument: string;
  noActionRequired: string;
proceedToNextStage: string;
send: string;
sent: string;
yes: string;
request: string;
requested: string;
requestDoc : string;
reviewDoc: string;
clickToProceed: string;
upload: string;
signTheSpa: string;
spa: string;
yetToInit: string;
  accessBuyers: string;
  terminateAccess: string;
  retractDeal: string;
  done: string;
  pendingBuyer: string;
pendingSeller: string;
  learnMore: string;
  tips: string;
  actions: string;
  documents: string;
  successMessage: string;
  docUpdated: string;
  failMessage: string;
  addedMessage: string;
  back: string;
  sureToDelete: string;
  buyerLogout: string;
  buyerConfirmationTxt: string;
  sellerLogout: string;
  sellerConfirmationTxt: string;
  ySp: string;
nKh: string;
  }
  clientId : string;
  clientName: string;
  clientBio: string;
  clientNamear: string,
  clientBioar: string,
  clientImage: string;
  isSellerProceedstage1: boolean;
  isBuyerProceedstage1: boolean;
  openDeleteModal: boolean;
  confirmLogOut: boolean;
  deleteDocument: Documents;
  isExpanded: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AcquisitionProcessController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef = React.createRef<HTMLInputElement>();
  saleInputFile = React.createRef<HTMLInputElement>();
  getDealsDetailsId ="";
  geChatAPIId ="";
  readChatAPIId ="";
  stage1ApiId="";
  latterOfIntentDraftId="";
  sendLetterOfIntentId = "";
  getLOIDocumentId ="";
  reviewLoiDocumentId="";
  requestedDocumentdId ="";
  getStage3DocumentsId = "";
  salePurchaseUploadId = "";
  getProfileDetailsId = "";
  getStageContentApiCallId = "";
  updateFilesId="";
  updateDocumentsFromSellerId="";
  terminateAccessId="";
  getEvaluationStatusAPICallId="";
  getClientDataAPIcallId: string = "";
  deleteDocumentAPIcallId: string = "";
  diligenceDocAPIcallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 1,
      openLearnMoreDialog: false,
      selectedLearnMoreContent: { url: "", title: "", description: "", arabic_title: "", arabic_description: "" },
      terminateAccess: false,
      stepActionTab: "Documents",
      accessRequestId :null,
      dealId:0,
      profileId: '',
      chatId: '',
      userType: "",
      profileName:"",
      description:"",
      profileImage:"",
      keyWords:[],
      tipsVideos:[],
      documentsSharedByMe:[],
      documentsSharedWithMe:[],
      unreadCount: 0,
      // Document Tab
      openUploadDocumentDialog: false,
      uploadDocumentFile: null,
      // Action Tab 2
      isLOIDocumentUploaded: false,
      isLOIReviewChecked: false,
      isLOIAccepted: false,
      loiDocument: "",
      loiDocumentId: null,
      loiDocumentLink : "",
      loiStatus: false,
      loiStatusSend:true,
      // Action Tab 3
      requestedDocumentsList: initialValueForRequestedDocuments,
      isSalePurchaseAgreementUploaded: false,
      isSalePurchaseAgreementDone: false,
      requestedDocumentsForSeller : [""],
      reviedDocuments:[],
      salesPurchaseDocument:'',
      dueDiligeneId:0,
      salePurchaseAgremntId:0,
      salesPurchaseAgeementUrl:"",
      learnmoreList:[],
      uploadDocumentTitle:"",
      isRequestedDocsAcc:false,
      isReviewDocsAcc: false,
      isSalePurchaseDocAcc:false,
      isDocumentSent:false,
      isDocumentsRevied:false,
      isSalePurchaseDocSent:false,
      isReviewDocuments:false,
      // Action Tab 4
      isStepFourDone: false,
      profileBoi:"",
      listingName:"",
      displayChat: false,
      dealDetails: {},
      language: "",
      pageText: {
        areYouSure: "",
retBuyer: "",
terSeller: "",
yias: "",
ntmb: "",
        makeAnOffer: "",
transactionAndConclusion: "",
dueDiligence: "",
        congrat: "",
        acquiring: "",
        selling: "",
        yourSu: "",
        inte: "",
        share: "",
        wn: "",
        got: "",
        exploreMarket: "",
        buyOther: "",
        intrestedToSell: "",
        becomeSeller: "",
        add: "",
        intr: "",
        become: "",
        sys: "",
great: "",
hasThe: "",
no: "",
hereNext: "",
checkTerms: "",
makeSure: "",
use: "",
safe: "",
signDocs: "",
get: "",
consult: "",
have: "",
paid: "",
once: "",
needHelp: "",
resourceCenter: "",
orGetItTouchWith: "",
support: "",
        noActionRequired: "",
        plSign: "",
proceedToNextStage: "",
review: "",
proceed:"",
stageComplete: "",
title: "",
upload: "", 
docReqSuc: "",
moveToD: "",
yes: "",
send: "",
sent: "",
letterOfIntent: "",
spaSent: "",
iHaveRead: "",
done: "",
construct: "",
loiSent: "",
underReview: "",
loiProceed:"",
request:"",
requested:"",
requestDoc:"",
reviewDoc:"",
clickToProceed: "",
signTheSpa: "",
spa:"",
yetToInit:"",
        listing: "",
        actions: "",
        documents: "",
        back: "",
        tips: "",
        terminateAccess: "",
        retractDeal: "",
        pendingBuyer: "",
pendingSeller: "",
        evaluateTheCompany: "",
        videos: "",
        keywords: "",
        withMe: "",
  myMe: "",
  uploadDocument: "",
      viewProfile: "",
      handlingProposals: "",
      undergoingDueDiligence: "",
      facilitatePayment: "",
      finalizeAcquisition: "",
      finalized: "",
      contactSupport: "",
      needHelpText: "",
      moveToLastStage: "",
      buyers: "",
      autoApprove: "",
      accepted: "",
      rejected: "",
      pending: "",
      noData: "",
      stage: "",
      accept: "",
      reject: "",
        sureToDelete: "",
      successMessage: "",
      docUpdated: "",
      buyerLogout: "",
      sellerLogout: "",
      sellerConfirmationTxt: "",
      buyerConfirmationTxt: "",
      failMessage: "",
      addedMessage: "",
      accessBuyers: "",
      learnMore: "",
      ySp: "",
nKh: ""
      },
      clientId: "",
      clientName: "Client Name",
      clientBio: "Client's Bio",
      clientNamear: 'اسم العميل',
      clientBioar: 'السيرة الذاتية للعميل',
      clientImage: "",
      isSellerProceedstage1: false,
      confirmLogOut: false,
      openDeleteModal: false,
      isExpanded: false,
      isBuyerProceedstage1: false,
      deleteDocument: { title: "", id: 0, url: "" }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseAPIId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getDealsDetailsId === responseAPIId) {
        this.dealsMapping(responseJson);
      }
      if (this.geChatAPIId === responseAPIId) {
        this.receiveChatResponse(responseJson);
      }
      if (this.readChatAPIId === responseAPIId) {
        this.setState({displayChat: !this.state.displayChat, stepActionTab: 'Actions', unreadCount: 0})

      }
      if (this.getClientDataAPIcallId === responseAPIId) {
        this.setClientData(responseJson);
      };

      this.handleAnyApiResponse(message);

      if(this.getLOIDocumentId === responseAPIId){
        this.LoiStatusMapping(responseJson);
      }
      if(this.getStage3DocumentsId === responseAPIId){
        this.mapStage3Documents(responseJson)
      }
      this.maintainTerminateAccess(responseAPIId,responseJson);
      this.apiCalls(responseAPIId,responseJson);
      this.handleEvaluationProcessResponse(responseAPIId,responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }
  translateTexts = async () => {
    this.setState({pageText: this.state.language === 'en' ? configJSON.staticEnText : configJSON.staticArText})
  }

  getEvaluationStatusFn = async () => {
    const header = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.requestApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEvaluationStatusAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/company_evaluations`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEvaluationProcessResponse = (responseAPIId: string, responseJson: {
    company: {
      status_buyer: string,
      status_seller: string
    }
  }) => {
    if (this.getEvaluationStatusAPICallId === responseAPIId) {
      if (responseJson?.company) {
        let result = conditionSort(this.state.userType === configJSON.buyer, responseJson.company.status_buyer, responseJson.company.status_seller )
        this.setState({ isBuyerProceedstage1: conditionSort(result == 'accepted', true, false),
         isSellerProceedstage1: conditionSort(result == 'proceeded', true, false) });
      }
    }
  }
  maintainTerminateAccess = async(responseAPIId:string, responseJson: {message : string}) => {
    if(this.terminateAccessId === responseAPIId){
      if(responseJson.message){
        toast.success(responseJson.message, {position: this.toastPosition()});
        const user = await getStorageData("typeOfUser");
        if(user == 'buyer') {
          window.location.href = "/Catalogue";
        } else{
          window.history.back();
        }
      }
    }
  }
  apiCalls = (responseAPIId:string,responseJson:any)=>{
    if (this.getProfileDetailsId === responseAPIId) {
      this.setState({ profileName: responseJson.data.attributes.first_name + " " + responseJson.data.attributes.last_name })
      this.setState({ profileImage: responseJson.data.attributes.image?.url, profileBoi: responseJson.data.attributes.bio });
    }
    if(this.getStageContentApiCallId === responseAPIId){
      this.mapContentData(responseJson);
    }
    if(this.updateFilesId === responseAPIId){
      if(responseJson.data){
        toast.success(this.state.pageText.addedMessage, { position: this.toastPosition() });
        this.getDealDetails(this.state.accessRequestId);
        this.setState({ uploadDocumentFile: null, uploadDocumentTitle: "" });
      }else{
        toast.error(this.state.pageText.failMessage, { position: this.toastPosition() });
        this.setState({ uploadDocumentFile: null, uploadDocumentTitle: "" });
      }
    }
    if(this.sendLetterOfIntentId ===responseAPIId){
      if(responseJson.data){
        this.setState({loiStatus: true, loiStatusSend: false})
        toast.success(this.state.pageText.loiSent, {position: this.toastPosition()});
        
      }
    }
    if(this.requestedDocumentdId === responseAPIId){
      if(responseJson.data){
        toast.success(this.state.pageText.docReqSuc, {position: this.toastPosition()});
        this.setState({isDocumentSent: true, isExpanded: false});
      }else{
        toast.error(this.state.pageText.failMessage, {position: this.toastPosition()});
      }
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    let language: string = await getStorageData("language") || 'en';
    this.setState({language: language}, () => {
      this.getProfileDetails();
      this.translateTexts();
    });
    this.getUserType();
    this.setState({accessRequestId : await getStorageData("id")})
    this.getDealDetails(await getStorageData("id"));
    this.getLoiId();
    this.getSalePurchaseId();
  }

 
  handleActiveStepOnChange = () => {
    this.stage1Api();
    const userType = conditionSort(this.state.userType === configJSON.buyer, this.state.pageText.pendingSeller, this.state.pageText.pendingBuyer);
    toast.success(userType, {position: this.toastPosition()});
  };

  handleActiveStepOneChange = () => {
    this.stage1Api();
    const userType = conditionSort(this.state.userType === configJSON.buyer, this.state.pageText.pendingSeller, this.state.pageText.pendingBuyer);
    this.activeStepOneChange();
    toast.success(userType, {position: this.toastPosition()});
  };

  getUserType = async() => {
    const user = await getStorageData("typeOfUser");
    this.setState({userType:user})
  }

  getSalePurchaseId =async () => {
    const salePurchaseId = await getStorageData("saleId");
    if(salePurchaseId){
      this.setState({salePurchaseAgremntId : salePurchaseId, isSalePurchaseAgreementUploaded: true})
    }
  }
  getLoiId =async () => {
    const letterOfSignId = await getStorageData("letterofIntentId");
    if(letterOfSignId){
      this.setState({loiDocumentId : letterOfSignId, isLOIDocumentUploaded: true});
    }
  }

  handleLearnMoreOnClick = (
    open: boolean,
    content: { url: string; title: string; description: string; arabic_title: string; arabic_description: string }
  ) => {
    this.setState({
      openLearnMoreDialog: open,
      selectedLearnMoreContent: content,
    });
  };

  handleTabChange = (value: string) => {
    this.setState({ stepActionTab: value });
  };

  // Document Tab
  handleUploadDocumentOnClick = (open: boolean) => {
    if (!open) {
      this.setState({ uploadDocumentTitle: "", uploadDocumentFile: null });
    }
    this.setState({ openUploadDocumentDialog: open });
  };

  handleUploadDocumentFileInputChange = (e: any, isClear?: boolean) => {
    if (isClear) {
      this.setState({ uploadDocumentFile: null, uploadDocumentTitle:"" });
    }
    if (e && e.target && e.target.files && e.target.files[0]) {
      this.setState({ uploadDocumentFile: e.target.files[0] });
    }
  };

  // Action Tab 2
  handleLOIDocumentUpload = () => {
    if(this.state.loiDocumentLink){
      window.open(this.state.loiDocumentLink, "_blank");
    }else{
      this.handleNavigation("LetterOfIntentTerms")
  }
  }

  handleNavigation =(navigatorItem: string, bypassCondition?: boolean) =>{
    if (this.state.userType ==="buyer" || bypassCondition) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), navigatorItem);
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg)
    }
  }
 
  handleSalesPurchaseDocumentUpload = () => {
    if(this.state.salesPurchaseDocument){
      window.open(this.state.salesPurchaseDocument, "_blank");
    }else{
      this.handleNavigation("SalePurchaseAgreementTerms");
  }
  }

  handleLOIReviewChecked = (event: any) => {
    this.setState({ isLOIReviewChecked: event.target.checked });
  };

  handleLOIProceedClick = () => {
    this.ReviewLoiDocument();
    this.setState({
      isLOIAccepted: true,
    });
  };

  // Action Tab 3
  handleRequestedDocumentUploadFile = (e: any, id: number) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const newRequestedDocumentsList = this.state.requestedDocumentsList.map(
        (list) => {
          if (id === list.id) {
            list.file = e.target.files[0];
            return list;
          }
          return list;
        }
      );
      this.setState({ requestedDocumentsList: newRequestedDocumentsList });
    }
  };

  handleSalePurchaseAgreementUpload = () => {
    this.setState({
      isSalePurchaseAgreementUploaded: true,
    });
  };

  handleSalePurchaseAgreementProceed = () => {
    this.updateStatusOfSalesPurchaseBySeller();
    this.setState({
      isSalePurchaseAgreementDone: true,
    });
  };

  // Action Tab 4
  handleStepFourYesClick = () => {
    this.setState({
      isStepFourDone: true,
    });
  };

  inputs = () =>{
    this.setState({requestedDocumentsForSeller : [...this.state.requestedDocumentsForSeller,""]})
  }
  onInputChange = (index:number, e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputs = this.state.requestedDocumentsForSeller
      inputs[index] = e.target.value;
      this.setState({requestedDocumentsForSeller:inputs})
  }


  sendDocumentToSeller =async () => {
      const token = await getStorageData("token");
      const header = {
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.sendLetterOfIntentId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_request_management/deals/${this.state.dealId}/handling_proposals/${this.state.loiDocumentId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateRequestTextApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDealDetails = async (id:number| null) =>{
    id && await setStorageData("accessId",id)
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealsDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals?access_request_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLoIDocument = async () =>{
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLOIDocumentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/handling_proposals`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  LoiStatusMapping = (responseJson:any) =>{
    if(responseJson.data){
      this.setState({loiDocumentLink : responseJson.data.attributes.letter_of_intent.url, loiDocumentId:responseJson.data.attributes.letter_of_intent.id ,isLOIDocumentUploaded : responseJson.data.attributes.letter_of_intent?.url ? true : false,
         loiStatus : responseJson.data.attributes.reviewed === "pending" && responseJson.data.attributes.status === "sent",loiStatusSend : responseJson.data.attributes.status !== "sent"});
    }
  }

  dealsMapping = async (responseJson: any) => {
    if(responseJson?.data){
      const {data} = responseJson;
      this.setState({dealId: data.id, listingName: data.attributes.listing_name,dealDetails: data.attributes}, async() => {
        this.getLoIDocument();
        this.getReviewDocuemntsOfStage3();
        await setStorageData("dealId", data.id)
      });
      const stage = responseJson.data.attributes.stage;
      const stageNumber = parseInt(stage.slice(-1));
      this.setState({activeStep : stageNumber,documentsSharedByMe: data?.attributes?.docs?.attributes?.shared_by_me,
        documentsSharedWithMe: data.attributes.docs.attributes.shared_with_me,
      },() =>{
  this.getEvaluationStatusFn();
  this.getStageContent();
  this.getChatData(data);
})
      if (this.state.userType === configJSON.buyer) {
        this.accessClientDetails(data.attributes.seller_id);
      }
      else {
        this.accessClientDetails(data.attributes.buyer_id);
      }
}
}

  getChatData = async (dataObj: {attributes: {buyer_id: string, seller_id: string}, id: string}) => {
    let userType = await getStorageData("typeOfUser");
    this.setState({profileId: userType === 'buyer' ? dataObj.attributes.buyer_id : dataObj.attributes.seller_id});
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.geChatAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCHatByDeal}?deal_id=${dataObj.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveChatResponse = (responseJson: any) => {
    let unreadCount = responseJson.data.attributes.accounts_chats.find((value: any) => value.attributes.account_id === this.state.profileId);
    this.setState({unreadCount: unreadCount.attributes.unread_count, chatId: responseJson.data.id})
  }

  stage1Api = async() =>{
    const token = await getStorageData("token");
    const header = {
      token: token,
    };
    this.callFunction();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    await removeStorageData("letterofIntentId");
    await removeStorageData("saleId");
    this.stage1ApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/company_evaluations`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  stage4Api = async() =>{
    this.setState({isStepFourDone: true})
    const token = await getStorageData("token");
    const header = {
      token: token,
    };
    this.getStageContent()
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stage1ApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/asset_handovers`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateStatusOfSalesPurchaseBySeller = async() =>{
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    this.getStageContent()
    const httpBody = {
      due_diligence :{
        status: "reviewed"
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stage1ApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/due_diligences/review_agreement`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateStatusOfSalesPurchaseBybuyer = async() =>{
    const token = await getStorageData("token");
    const header = {
      token: token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody = {
      due_diligence:{
        status:"sent"
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    toast.success(this.state.pageText.spaSent, {position: this.toastPosition()});
    this.setState({isSalePurchaseDocSent:true})
    this.stage1ApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/due_diligences/${this.state.salePurchaseAgremntId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ReviewLoiDocument = async() =>{
    const token = await getStorageData("token");
    const header = {
      token: token,
    };
    this.getStageContent()
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reviewLoiDocumentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/handling_proposals/review_letter_of_intent`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 

  requestDocuments = async() =>{
    const token = await getStorageData("token");
    const privateInfoHeader = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const requestedDocuments = new FormData();
    this.state.requestedDocumentsForSeller.forEach((title, index) => {
      requestedDocuments.append(`due_diligence[diligence_documents_attributes][${index}][title]`, title);
    });
    this.requestedDocumentdId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/due_diligences`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(privateInfoHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSendRequestApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestedDocuments
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReviewDocuemntsOfStage3 = async () =>{
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStage3DocumentsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/due_diligences`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  mapStage3Documents = (responseJson:any) =>{
    const {data} = responseJson;
    const { requestedDocumentsForSeller } = this.state;
    if(responseJson?.data) this.setState({isDocumentSent: true});
    this.setState({dueDiligeneId: data?.id}, async()=>{
        await setStorageData("dueDiligenceId", data?.id)
    })
    const documents = data?.attributes.diligence_documents;
    this.setState({reviedDocuments : documents || []})
    const sellerDocuments = documents?.map((item: { id: string, document_title: string, url: string }, docIndex: number) => {
      requestedDocumentsForSeller[docIndex] = item.document_title;
    return{
      file:null,
      title: item.document_title,
      id: item.id
    }
   })
   this.setState({salesPurchaseDocument: data?.attributes?.sell_purchase_agreement?.url || "", isSalePurchaseAgreementUploaded: data?.attributes?.sell_purchase_agreement?.url ? true: false,
   isSalePurchaseDocSent : data?.attributes?.status ==="draft" ? false: true})
   this.setState({requestedDocumentsList : sellerDocuments,requestedDocumentsForSeller:requestedDocumentsForSeller});
  }

  updateDocumentsFromSeller= async() => {
    const token = await getStorageData("token");
    const privateInfoHeader = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    toast.success(this.state.pageText.docUpdated, {position: this.toastPosition()});
    this.setState({isDocumentsRevied: true, isExpanded: false});
    const uploadDocuments = new FormData();
    uploadDocuments.append("due_diligence[document_status]","uploaded")
    this.state.requestedDocumentsList.forEach((item, index) => {
      uploadDocuments.append(`due_diligence[diligence_documents_attributes][${index}][document]`, item.file);
      uploadDocuments.append(`due_diligence[diligence_documents_attributes][${index}][id]`,`${item.id}`)
    });
    this.updateDocumentsFromSellerId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}/due_diligences/${this.state.dueDiligeneId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(privateInfoHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      uploadDocuments
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileDetails = async() =>{
    const token = await getStorageData('token');
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStageContent = async () =>{
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStageContentApiCallId = requestMessage.messageId;
    const URL = `bx_block_posts/stage_contents?stage=stage${this.state.activeStep}&content_for=${this.state.userType}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  mapContentData = (responseJson:any) =>{
    const {data} =responseJson;
    if(data?.length > 0){
      this.setState({learnmoreList: data[0].attributes.content_informations.map((info: {title:string,arabic_title:string,description:string,arabic_description:string,url:string}) => ({
        title: this.getSpecifiedLangString(this.state.language, info.title, info.arabic_title),
        description: this.getSpecifiedLangString(this.state.language, info.description, info.arabic_description),
        url: info.url,
        arabic_description: info.arabic_description,
        arabic_title: info.arabic_title,
      })),tipsVideos: data[0].attributes.tip_links, keyWords : data[0].attributes.tip_keywords})
    }
  }

  toggleChatComponent = () => {
    this.callReadChatApi();
  }

  callReadChatApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.readChatAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.readChatAPIEndpoint}?chat_id=${this.state.chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  callFunction = () => {
    setTimeout(this.getDealDetails(this.state.accessRequestId), 500)
  }

  addFiles = async(id:number) =>{
    this.setState({openUploadDocumentDialog:false});
    this.callFunction();
    const token = await getStorageData("token");
    const privateInfoHeader = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const uploadDocument = new FormData();
    uploadDocument.append("deal[deal_documents_attributes][0][document]", this.state.uploadDocumentFile);
    uploadDocument.append("deal[deal_documents_attributes][0][title]", this.state.uploadDocumentTitle);
    uploadDocument.append("deal[deal_documents_attributes][0][uploaded_by]", this.state.userType)
    this.updateFilesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_request_management/deals/${this.state.dealId}?access_request_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(privateInfoHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      uploadDocument
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

handleFileOpen = (url:string) =>{
  window.open(url, "_blank")
}

getIfCondition = (condition: boolean, ifTrue: any, ifFalse: any) => {
  return condition ? ifTrue : ifFalse;
};

onTerminateClick = async(userType: string) =>{
  const token = await getStorageData("token");
  if(userType ==="buyer"){
    const urlForTerminate = `account_block/access_requests/${this.state.accessRequestId}?locale=${this.state.language}`;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const httpBody = {
      access_request :{
        status: "rejected"
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.terminateAccessId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlForTerminate
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }else{
    const urlToRetract = `account_block/access_requests/${this.state.accessRequestId}/reject?locale=${this.state.language}`
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.terminateAccessId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlToRetract
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
 
}

  accessClientDetails = async (clientID: string) => {
    this.getClientDataAPIcallId = await this.apiCall({
      method: configJSON.httpGetMethod,
      type: configJSON.validationApiContentType,
      endPoint: `${configJSON.getClientDataEndPoint.replace("{clientID}", clientID)}`
    });
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body } = apiData;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setClientData = (response: ValidClientResponse & InvalidResponse) => {
    if (response.data) {
      const clientId = response.data.id;
    const clientName = response.data.attributes.first_name + " " + response.data.attributes.last_name;
    const clientBio = response.data.attributes.bio;
    const clientImage = response.data.attributes.image;
    this.setState({
      clientId: clientId,
      clientName: clientName,
      clientBio: clientBio,
      clientImage: clientImage?.url
    });
    }
    else {
      this.setState({
        clientName: configJSON.clientName,
        clientBio: configJSON.clientBio
    });
    }
  };

  viewClientProfile = () => {
    this.props.navigation.navigate("ViewBuyerProfile", { id: this.state.clientId })
  };

  activeStepOneChange = () => {
    if (this.state.userType === "seller") {
      this.setState({ isSellerProceedstage1: true });
    } else if (this.state.userType === "buyer") {
      this.setState({ isBuyerProceedstage1: true });
    }
  };

  toastPosition = () => {
    if (this.state.language === "ar") {
      return "top-left"
    }
    else {
      return "top-right"
    }
  };

  openDeleteModal = (deleteDocument: Documents) => {
    this.setState({ openDeleteModal: true, deleteDocument });
  };

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false, deleteDocument: { title: "", url: "", id: 0 } });
  };

  handleDeleteDocument = async () => {
    const { deleteDocument } = this.state;
    const containsObject = this.state.reviedDocuments.some(item =>
      item.id === deleteDocument.id &&
      item.document_title === deleteDocument.title &&
      item.url === deleteDocument.url
    );
    if (containsObject) {
      this.diligenceDocAPIcallId = await this.apiCall({
        method: configJSON.deleteRequestApiMethod,
        endPoint: `${configJSON.deleteDiligenceEndPoint.replace("{docId}", this.state.deleteDocument.id).replace("{dealId}", this.state.dealId)}`
      });
    } else {
      this.deleteDocumentAPIcallId = await this.apiCall({
        method: configJSON.deleteRequestApiMethod,
        endPoint: `${configJSON.deleteDocumentEndPoint.replace("{docId}", this.state.deleteDocument.id).replace("{accessReq}", this.state.accessRequestId)}`
      });
    }
  };

  handleAnyApiResponse = (message: Message) => {
    const responseAPIId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.deleteDocumentAPIcallId === responseAPIId || this.diligenceDocAPIcallId === responseAPIId) {
      if (responseJson.message) {
        let { documentsSharedByMe } = this.state;
        documentsSharedByMe = documentsSharedByMe.filter(document => document !== this.state.deleteDocument)
        this.setState({ documentsSharedByMe, deleteDocument: { title: "", url: "", id: 0 }, openDeleteModal: false });
        toast.success(this.state.pageText.successMessage, { position: this.toastPosition() });
      }
      else {
        toast.error(this.state.pageText.failMessage, { position: this.toastPosition() });
        this.setState({ openDeleteModal: false });
      }
    };

  };

  handleDocAccordian = () => {
    this.setState({ isExpanded: !this.state.isExpanded})
  };
  
  stageFiveUserNavigation = () => {
    if (this.state.userType === "seller") {
      this.handleNavigation("StartUpDetails", this.state.userType === "seller");
    }
    else {
      this.handleNavigation("Catalogue");
    };
  };

  navigateTosignIn = async () => {
    conditionSort(this.state.userType === "seller", window.open('https://zdj8qoqbmmb.typeform.com/to/eUUPypiV'), window.open('https://zdj8qoqbmmb.typeform.com/to/La15ERhV'));
  };

  handleHelpNavigation = () => {
    this.props.navigation.navigate("UpdateSettings", { option: "help" });
  };

  logOutModal = () => {
    this.setState({ confirmLogOut: !this.state.confirmLogOut });
  };

  // Customizable Area End
}
