import { ToastOptions } from "react-toastify";

export const isDisabledSubmit = (innnerThis: any): boolean => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    aboutYourself,
    numberAcquisitionClosed,
    numberProjectedAnnualAquisitions,
    selectedCountry,
    selectedCity,
    IsValidUrlMess,
    FirstNameError,
    LastNameError,
    AboutYourselfError,
    InValidAge,
    NumberAcquisitionError,
    ProjectedAquisitionsError,
    accreditedBuyer,
    role,
    gender
  } = innnerThis.state;

  if (innnerThis.props.sellerType) {
    return ( 
      !gender || !firstName?.trim() || !lastName?.trim() || !dateOfBirth?.trim() || !role  || 
      IsValidUrlMess.length > 0 || !aboutYourself?.trim() ||
      AboutYourselfError.length > 0 || InValidAge.length > 0 || FirstNameError.length > 0 || LastNameError.length > 0
    );
  }

  return (
    !gender || !firstName?.trim() || !lastName?.trim() || !dateOfBirth?.trim() || !role || 
    IsValidUrlMess.length > 0 || !aboutYourself?.trim() || !numberAcquisitionClosed ||
    !numberProjectedAnnualAquisitions || !selectedCountry?.trim() || !selectedCity?.trim() || AboutYourselfError.length > 0 || InValidAge.length > 0 || FirstNameError.length > 0 || LastNameError.length > 0 ||
    !innnerThis.hasDocument() || NumberAcquisitionError.length > 0 || ProjectedAquisitionsError.length > 0 || !accreditedBuyer?.trim()
  );
}

export const changeDirection = (language: string) => {
  const rootDiv = document.documentElement;
  if (rootDiv) {
    rootDiv.style.direction = language === 'ar' ? 'rtl' : 'ltr';
    rootDiv.setAttribute('lang', language);
  } else {
    console.error('Element with id "root" not found.');
  }
}

export const conditionSort = (condition: any, trueResult: any, falseResult: any) => {
  return condition ? trueResult : falseResult;
}

export const formatNumber = (number: string | null) => {
  if(!number) return number;
  const [integerPart, decimalPart] = number.toString().split(".");

  const formattedIntegerPart = integerPart.replace(/\D/g, '').split('').reverse().reduce((acc, char, index) => {
    return char + (index && index % 3 === 0 ? ',' : '') + acc;
  }, '');

  return conditionSort(decimalPart, `${formattedIntegerPart}`, formattedIntegerPart);

};

export const isEmptyObject = (obj: Record<string, any>): boolean => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};

export const isDirRtl = localStorage.getItem('language') == 'ar'

export const getToastPosition = () : ToastOptions => {
  const selectedLang = localStorage.getItem('language');
  let position = "";

  if (selectedLang == "ar") {
    position = "top-left"
  }
  else {
    position = "top-right"
  }
  return {
    position: position as ToastOptions['position'], style: {
      direction: isDirRtl ? 'rtl' : 'ltr'
    }
  }
}