import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import {WithStyles} from "@material-ui/core";
import { RouterProps } from "react-router"
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { setStorageData } from "framework/src/Utilities";
// Customizable Area Start
import { conditionSort, getToastPosition } from "../../../components/src/Helper";
import { FlagReportTypes } from "../../catalogue/src/CatalogueListController.web";

interface Themes  {
    primary: string;
    secondary: string;
    gray: string;
    grayLight: string;
    pink: string;
}

export interface MenuListType {
    component: string,
    label: string,
    class: string
}

interface TranslatableObject {
  [key: string]: string | TranslatableObject;
}

interface PageText {
  founded: string,
  employeeNumber: string,
  basedIn: string,
  category: string,
  askingprice: string,
  aboutSeller: string,
  documents: string,
  plStatement: string,
  balanceSheet: string,
  docFiles: string,
  role: string,
  aboutVendor: string,
  vendorList: string,
  ongoingContract: string,
  isYes: string,
  isNo: string,
  vendorContract: string,
  financials: string,
  totalDebt: string,
  totalAssets: string,
  totalEquity: string,
  sellReason: string,
  financialMethod: string,
  loan: string,
  selfFund: string,
  corpFund: string,
  equity: string,
  vcBacked: string,
  incubator: string,
  businessType: string,
  revenueModel: string,
  mainSupplier: string,
  regionMarket: string,
  primaryProd: string,
  mainCompititors: string,
  growthOppo: string,
  keyAsset: string,
  grossRevenue: string,
  netProfit: string,
  annualRev: string,
  growthRate: string,
  multipleType: string,
  multiNumber: string,
  customerMetrics: string,
  accessReqs: string,
  reqAccessDone: string,
  reqAccess: string,
  youHave: string,
  leftReq: string,
  resetDay: string,
  competitors: string,
  originalListing: string,
  english: string,
  arabic: string,
  findIntresting: string,
  viewSimilar: string,
  explore: string,
  canReq: string,
  viewReq: string,
  ndaTitle: string,
  cancelSubtitle: string,
  successSubtitle: string,
  successDesc: string,
  cancleConfirm: string,
  sureTxt: string,
  viewNda: string,
  noBack: string,
  success: string,
  yesCancel: string,
  ndaDescription: string,
  ndaSubTitle: string,
  viewList: string
}

export interface BookmarkDataTypes {
  type: string;
  id: string;
  attributes: {
    title: string;
    id: number;
  };
}
// Customizable Area End

export type Props = WithStyles & {
  navigation: any;
  id: string;
// Customizable Area Start
// Customizable Area End
}

interface S {
    // Customizable Area Start
    themeOne: Themes,
    themeTwo: Themes,
    isThemeOne: boolean;
    isDisplayCancelConfirmationModal: boolean,
    isCancelSucces: boolean;
    isAccess: boolean;
    component: string;
    navPosition: number;
    token: string;
    menuList: Array<MenuListType>;
    data: any;
    meta: any;
    requestRemaining: number;
    daysRemaining: number;
    isUserSubscribed: boolean;
    userType: string;
    language: string;
    pageText: PageText;
    flagBoxText:{
      chooseThisWillText:string;
      flagBoxDetails:string;
      flagTheListing:string;
      reportBlogTitle:string;
      shareBoxTitle:string;
      yesText:string;
      flagBoxTitle:string;
      archiveListingText:string;
      reportText:string;
      noText:string;
      ArchiveText:string;
      hideTheListingText:string;
    };
    isTranslated: boolean;
    bookmarkList: BookmarkDataTypes[];
    bookmarkModal: boolean;
    bookmarkCreate:boolean;
    pageTextValue : {
      addListTxt:string;
      listTitleTextField:string;
      bookmarkModalTitle:string;
    }
    flagHandle:boolean;
    flagModalStatus:boolean;
    flagArchiveModalState:boolean;
    flagReportList: Array<FlagReportTypes>;
    // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}


export default class ComanyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {

    // Customizable Area Start
    getAccessRequestAPI  = "";
    terminateAccessId = "";
    getDetailsRequestAPI = "";
    createAccessRequestAPI = "";
    getDeailRequestAPI = "";
    unBookmarkApiCallID = "";
    getBookmarkListApiCallId = "";
    createListApiCallID = "";
    bookmarkApiCallID = "";
    reportCatalogApiCallID = "";
    getFlagReportApiListCallID = "";
    archiveCatalogApiCallID = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        
        this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.CountryCodeMessage)
        ];
    
        this.state = {
            // Customizable Area Start
            isDisplayCancelConfirmationModal: false,
            themeOne: configJSON.themeOne,
            themeTwo: configJSON.themeTwo,
            isThemeOne: false,
            isCancelSucces: false,
            isAccess: false,
            component: "summery",
            navPosition: 230,
            menuList: [],
            token: "",
            data: {},
            meta: {},
            requestRemaining: 0,
            daysRemaining: 0,
            isUserSubscribed: true,
            userType: "",
            language: "",
          pageText: {
            founded: "",
            ndaTitle: "",
            success: "",
            cancelSubtitle: "",
            ndaSubTitle: "",
            yesCancel: "",
            successDesc: "",
            cancleConfirm: "",
            noBack: "",
            sureTxt: "",
            viewNda: "",
            successSubtitle: "",
            ndaDescription: "",
            employeeNumber: "",
            basedIn: "",
            category: "",
            askingprice: "",
            aboutSeller: "",
            documents: "",
            plStatement: "",
            balanceSheet: "",
            docFiles: "",
            role: "",
            aboutVendor: "",
            vendorList: "",
            ongoingContract: "",
            isYes: "",
            isNo: "",
            vendorContract: "",
            financials: "",
            totalDebt: "",
            totalAssets: "",
            totalEquity: "",
            sellReason: "",
            financialMethod: "",
            loan: "",
            selfFund: "",
            corpFund: "",
            equity: "",
            vcBacked: "",
            incubator: "",
            businessType: "",
            revenueModel: "",
            mainSupplier: "",
            regionMarket: "",
            primaryProd: "",
            mainCompititors: "",
            growthOppo: "",
            keyAsset: "",
            grossRevenue: "",
            netProfit: "",
            annualRev: "",
            growthRate: "",
            multipleType: "",
            multiNumber: "",
            customerMetrics: "",
            accessReqs: "",
            reqAccessDone: "",
            reqAccess: "",
            youHave: "",
            leftReq: "",
            resetDay: "",
            competitors: "",
            originalListing: "",
            english: "",
            arabic: "",
            findIntresting: "",
            viewSimilar: "",
            viewReq: "",
            explore: "",
            canReq: "",
            viewList: ""
          },
            isTranslated: false,
            bookmarkList: [],
            bookmarkModal: false,
            bookmarkCreate:false,
            pageTextValue : {
              bookmarkModalTitle: configJSON.bookmarkModalTitle,
              addListTxt: configJSON.addListTxt,
              listTitleTextField:"listTitle",
            },
            flagBoxText:{
            chooseThisWillText: configJSON.chooseThisWillText,
            flagBoxDetails: configJSON.flagBoxDetails,
            flagTheListing: configJSON.flagTheListing,
            reportBlogTitle: configJSON.reportBlogTitle,
            shareBoxTitle: configJSON.shareBoxTitle,
            yesText: configJSON.yesText,
            flagBoxTitle: configJSON.flagBoxTitle,
            archiveListingText: configJSON.archiveListingText,
            reportText: configJSON.reportText,
            noText: configJSON.noText,
            ArchiveText: configJSON.ArchiveText,
            hideTheListingText: configJSON.hideTheListingText,
            },
            flagHandle:false,
            flagModalStatus:false,
            flagArchiveModalState:false,
            flagReportList: [
              {
                id: "1",
                type: "catalogue_list",
                attributes: {
                  id: 1,
                  name: "Inaccurate Listing Details",
                },
              },
              {
                id: "2",
                type: "catalogue_list",
                attributes: {
                  id: 2,
                  name: "Suspicious or Fraudulent",
                },
              },
              {
                id: "3",
                type: "catalogue_list",
                attributes: {
                  id: 3,
                  name: "Irrelevent or spam",
                },
              },
              {
                id: "36",
                type: "catalogue_list",
                attributes: {
                  id: 36,
                  name: "Inappropriate Content",
                },
              },
              {
                id: "36",
                type: "catalogue_list",
                attributes: {
                  id: 36,
                  name: "Duplicate Listing",
                },
              },
              {
                id: "4",
                type: "catalogue_list",
                attributes: {
                  id: 4,
                  name: "Breach of Terms of Service",
                },
              },
            ],
            // Customizable Area End
        }
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
      // runEngine.debugLog("Message Recived", message);
      if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        const token = await getStorageData("token");
        this.receiveTokenValue(token);
      }
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const responseAPIId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        // get response
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (this.getDetailsRequestAPI === responseAPIId) {
          this.receiveDetailsRequest(responseJson);
        }
        if (this.getAccessRequestAPI === responseAPIId) {
          this.receiveAccessRequest(responseJson);
        }
        if (this.getDeailRequestAPI === responseAPIId) {
          this.receiveDealRequest(responseJson);
        }
          this.recieveUnBookmark(message)
          this.recieveCreateTitle(message)
          this.recieveBookmark(message)
          this.recieveBookmarkList(message)
          this.recieveArchive(message)
          this.receiveFlagReportList(message)
          this.recieveReport(message)
          this.receiveTerminate(message)
        // getDeailRequestAPI
        if(this.createAccessRequestAPI === responseAPIId) {
          if(responseJson && responseJson.errors && responseJson.errors?.[0]) {
            toast.error(responseJson.errors[0], getToastPosition())
          }
          else {
            this.setState({requestRemaining: this.state.requestRemaining - 1})
            this.getDetailsRequest()
          }
        }
      }
        // Customizable Area End
    }

    // Customizable Area Start

    toggleCancelModal = () => this.setState({isDisplayCancelConfirmationModal: !this.state.isDisplayCancelConfirmationModal});
  toggleSuccess = () => {
    this.setState({ isCancelSucces: !this.state.isCancelSucces });
    this.getAccessRequest();
    this.getDetailsRequest();
  };

  onTerminateClick = async () => {
    const accessId = await getStorageData("accessId");
    const urlForTerminate = configJSON.cancelRequestEndPoint.replace("{accessId}", accessId);
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.terminateAccessId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlForTerminate+`?locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
     
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    let userType = await getStorageData("typeOfUser");
    let language = await getStorageData("language") || 'en';
    if (language === "ar") {
      this.setState({ pageText: configJSON.pageTextAr, menuList: configJSON.menuListAr,   pageTextValue : {
              bookmarkModalTitle: configJSON.bookmarkModalTitleArabic,
              addListTxt: configJSON.addListTxtArabic,
              listTitleTextField:"listTitle",
            } });
    } else {
      this.setState({ pageText: configJSON.pageText, menuList: configJSON.menuList });
    }
    this.setState({ userType: userType, language: language });
  }

  getResponseVariablesData = (message:Message) => {
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    return [apiRequestCallID,responseJSON]
  }

  recieveUnBookmark = (message: Message) => {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (this.unBookmarkApiCallID === apiRequestCallID) {
      if (responseJSON && responseJSON.message) {
        toast.success(responseJSON.message, getToastPosition());
        this.getDetailsRequest()
      } else if (responseJSON && responseJSON.errors) {
        toast.error(responseJSON.errors.token, getToastPosition());
      }
    }
  };

  recieveBookmark = (message: Message) => {
    const [apiRequestCallId, responseJson] = this.getResponseVariablesData(message);
    if (this.bookmarkApiCallID === apiRequestCallId) {
      if (responseJson && responseJson.message) {
        this.setState({
          bookmarkModal: false,
        });
        toast.success(responseJson.message[0], getToastPosition())
      } else if (responseJson && responseJson.errors) {
        this.setState(
          {
            bookmarkModal: false,
          },
          () => toast.error(responseJson.errors.token, getToastPosition())
        );
      }
      this.getDetailsRequest()
    }
  };

  recieveBookmarkList = (message: Message) => {
    const [apiRequestCallID, responseJSON] = this.getResponseVariablesData(message);
    if (this.getBookmarkListApiCallId === apiRequestCallID) {

      if (responseJSON && responseJSON.data) {
        
        this.setState({
          bookmarkList: responseJSON.data,
          bookmarkModal: true,
        });
      }
    }
  };

  getIfElseCondition = (condition: boolean, ifTrue: any, ifFalse: any) => {
    return condition ? ifTrue : ifFalse;
  };
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receiveTokenValue = async(token: string) =>  {
    let isUserSubscribed = await getStorageData("isUserSubscribed");
    isUserSubscribed = conditionSort(isUserSubscribed == 'true', true, false);
    this.setState({isUserSubscribed: isUserSubscribed})
    this.setState({ token: token }, () => {
      if (token) {
        
        this.getAccessRequest();
        this.getDetailsRequest();
      } else {
        this.navigateToSignIn("EmailAccountLoginBlock");
      }
    });
  };

  // access request
  getAccessRequest = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };

    let endPoint = `${configJSON.getAccessCountEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccessRequestAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDealsRequest = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };

    let endPoint = `${configJSON.getDealsStatusEndPoint}?deals_no_nda=${true}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeailRequestAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  receiveDealRequest = (responseJson: {data?: [{attributes: {listing_id: string, auto_sign_nda: string}, id: string}]}) => {
   
     if (responseJson.data?.length ) {
       let tempListing = responseJson.data.reverse();
       let found = tempListing.find((respObj: { attributes: { listing_id: string } }) => respObj.attributes.listing_id == this.state.data.id);

      if(found) {
        this.setState({data: {...this.state.data, auto_sign_nda: found.attributes.auto_sign_nda}});
        setStorageData("accessId", found.id)

      }
    }
  }
  receiveAccessRequest = (responseJson: any) => {
    if(responseJson && responseJson.errors && responseJson.errors?.[0]?.token) {
      this.navigateToSignIn("EmailAccountLoginBlock");

    }
     if (responseJson && responseJson.total_requests_remaining_this_week) {
      this.setState({requestRemaining: responseJson.total_requests_remaining_this_week});
    }
    if (responseJson && responseJson.days_left_until_reset) {
      this.setState({daysRemaining: responseJson.days_left_until_reset});
    }
  }

  getContent = (count:number,isRequest:boolean = true) => {
    
    let value:string = ""
    if(isRequest){
      value = `تبقى لديك ${count} طلبات وصول لهذا الأسبوع
      `;
      (count === 1) && (value = "تبقى لديك طلب وصول واحد لهذا الأسبوع");
      (count === 2) && (value = "تبقى لديك طلبا وصول لهذا الأسبوع");
    }else {
      value = `تبقى ${count} أيام لإعادة التعيين`;
      (count === 1) && (value = " تبقى يوم لإعادة التعيين");
      (count === 2) && (value = "تبقى يومان لإعادة التعيين");
    }
    return value;
  }

  // get detail by id
  getDetailsRequest = async() => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };
    
    let profileId: string = this.props.navigation.getParam("id");
    await setStorageData("listingIdForNDA", profileId);
    let endPoint = `${configJSON.getBusinessDetailEndpoint}/${profileId}?arabic_translate=true`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailsRequestAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  receiveDetailsRequest = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      let temp = { ...responseJson.data.attributes };
      if (responseJson.meta.access_request === "accepted" || responseJson.meta.access_request === "cancelled") {
        temp.private_information = temp.private_information.attributes;
        temp.summarry = temp.summarry.attributes;
        temp.company_overview = conditionSort(temp.company_overview?.attributes, temp.company_overview.attributes, temp.company_overview)
        temp.acquisition_detail = temp.acquisition_detail.attributes;
        temp.financial = temp.financial.attributes;
        temp.customer_metric = temp.customer_metric.attributes;
      }
      else {
        temp.private_information = temp.private_information.data.attributes;
        temp.summarry = temp.summarry.data.attributes;
        temp.company_overview = conditionSort(temp.company_overview?.attributes, temp.company_overview.attributes, temp.company_overview);
        temp.acquisition_detail = temp.acquisition_detail.data.attributes;
        temp.financial = temp.financial.data.attributes;
        temp.customer_metric = temp.customer_metric.data.attributes;
      }
      temp.company_overview = temp.company_overview?.data?.attributes ? temp.company_overview.data.attributes: temp.company_overview;
  
      temp = this.decision(temp)
      let isbusiness = temp.category_name === "SME";
      this.setState({ data: temp, meta: responseJson.meta, isThemeOne: isbusiness }, () => {
        this.getDealsRequest();
        this.setState({ isTranslated: true })
   
      });
    }
  }

  decision = (temp: any) => {
    
    if (temp.company_overview && temp.company_overview.company_overview) {

      temp.company_overview = { ...temp.company_overview, ...temp.company_overview.company_overview }
      if (temp.company_overview.company_growth_opportunity) {
        const growthOpportunities: { [key: string]: string } = {
          improve_conversion_rates: "Improve Conversion Rates",
          increase_content_marketing: "Increase Content Marketing",
          expand_to_new_markets: "Expand to New Markets",
          increase_pricing: "Increase Pricing",
          hire_b2b_sales_team: "Hire a B2B Sales Team",
          focus_on_seo: "Focus on SEO",
          increase_digital_marketing: "Increase Digital Marketing",
          add_new_product_features: "Add New Product Features",
          social_media_marketing: "Social Media Marketing"
        }

        const growthAr: {[key: string]: string} = {
          improve_conversion_rates: "تحسين معدلات التحويل",
increase_content_marketing: "زيادة تسويق المحتوى",
expand_to_new_markets: "التوسع إلى أسواق جديدة",
increase_pricing: "زيادة الأسعار",
hire_b2b_sales_team: "توظيف فريق مبيعات B2B",
focus_on_seo: "التركيز على تحسين محركات البحث",
increase_digital_marketing: "زيادة التسويق الرقمي",
add_new_product_features: "إضافة ميزات منتج جديد",
social_media_marketing: "التسويق عبر وسائل التواصل الاجتماعي",
        }

        let tempGO = temp.company_overview.company_growth_opportunity.data ? temp.company_overview.company_growth_opportunity.data.attributes: temp.company_overview.company_growth_opportunity;
        const filteredObj = Object.fromEntries(
          Object.entries(tempGO).filter(([key, value]) => value === true)
        );

        let tempGO1 = temp.company_overview.company_growth_opportunity.data ? temp.company_overview.company_growth_opportunity.data.attributes: temp.company_overview.company_growth_opportunity;
        const filteredObj1 = Object.fromEntries(
          Object.entries(tempGO1).filter(([key, value]) => value === true)
        );

        const resultArray = Object.keys(filteredObj).map(value => growthOpportunities[value]);
        const resultArray1 = Object.keys(filteredObj1).map(value => growthAr[value]);
        temp.company_overview.growth_opportunity = resultArray;
        temp.company_overview.arabic_growth_opportunity = resultArray1;

      }
      if (temp.company_overview.company_key_asset) {
        const keyAssets: { [key: string]: string } =
        {
          codebase_and_ip: "Codebase and IP",
          social_media_accounts: "Social Media Accounts",
          customers: "Customers",
          website: "Website",
          marketing_materials: "Marketing Materials",
          mobile_application: "Mobile Application",
          brand: "Brand",
          domain: "Domain"
        };

        const keyAssetsAr: { [key: string]: string } =
        {
          codebase_and_ip: "قاعدة الرموز والملكية الفكرية",
          social_media_accounts: "حسابات وسائل التواصل الاجتماعي",
          customers: "العملاء",
          website: "الموقع الإلكتروني",
          marketing_materials: "المواد التسويقية",
          mobile_application: "تطبيق الهاتف المحمول",
          brand: "العلامة التجارية",
          domain: "المجال"
        }

        let tempKey1 = temp.company_overview.company_key_asset.data? temp.company_overview.company_key_asset.data.attributes: temp.company_overview.company_key_asset;
        const filteredObjk = Object.fromEntries(
          Object.entries(tempKey1).filter(([key, value]) => value === true)
        );

        const resultArray11 = Object.keys(filteredObjk).map(value => keyAssets[value]);
        temp.company_overview.key_assets = resultArray11;

        let tempKey = temp.company_overview.company_key_asset.data? temp.company_overview.company_key_asset.data.attributes: temp.company_overview.company_key_asset;
        const filteredObj1 = Object.fromEntries(
          Object.entries(tempKey).filter(([key, value]) => value === true)
        );

        const resultArray1 = Object.keys(filteredObj1).map(value => keyAssetsAr[value]);
        temp.company_overview.arabic_key_assets = resultArray1;
      }

    }
    return temp;
  }

  openDoc = (url: string) => {
    window.open(url, "_blank")
  }

  handleToggleSubscribe = () => {
    this.setState({isUserSubscribed: true});
  }

  handleAccessRequest = async () => {
    let isSubscribed = await getStorageData("isUserSubscribed");
    if(isSubscribed != 'true') {
      this.navigateToSignIn("BuyerSubscription");
      return;
    }
    const header = {
      "Content-Type": configJSON.contentType,
      token: this.state.token,
    };

    let profileId: string = this.props.navigation.getParam("id");

    let data = {
      "access_request": {
      listing_id: profileId,
      seller_id: this.state.data.account_id
      }
    }
    let endPoint = `${configJSON.createAccessRequestEndpoint}?locale=${this.state.language}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccessRequestAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToSignIn = (block: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), block);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
  }

  handleChangeComponent = (option: string) => {
    this.getActiveNav(option);
    this.setState({ component: option, navPosition: configJSON.navPositions[option] }, () => this.getActiveNav(option));
  }

  getActiveNav = (option: string) => {
    const { menuList } = this.state;
    let newMenu = menuList.map((item: MenuListType, index) => {
      item.class = ""
      if (menuList[index + 1] != undefined && menuList[index + 1].class === "active") {
        item.class = "prev-active"
      }
      if (menuList[index + 2] != undefined && menuList[index + 2].class === "active") {
        item.class = "old-prev-active"
      }
      if (item.component === option) {
        item.class = "active"
      }
      return item;
    });
    this.setState({ menuList: newMenu });

 }

  handleBeBuyer = () => {
    window.location.href = 'https://zdj8qoqbmmb.typeform.com/to/eUUPypiV';
  };

  handleCloseBuyer = () => {
    let existRecord = window.document.getElementById("anchorID") as HTMLAnchorElement;
    existRecord.href = "/Catalogue";
  };

  isAccessRequestAccept = () => {
    if (this.state.meta.access_request === "accepted") {
      return true;
    } else {
      return false;
    }
  };


  handleDataKey = (dataKey: string) => {
    switch (dataKey) {
      case "type":
        return false;
      case "url":
        return false;
      case "email":
        return false;
      case "unique_auth_id":
        return false;
      case "preffered_language":
        return false;
      case "social_media_link":
        return false;
      case "user_type":
        return false;
      case "company_website":
        return false;
      case "file_name":
        return false;
      default:
        return true;
    }
  };

  generateApiRequestMessage = async (
    endpoint: string,
    type: string, body: any = null
  ) => {
    const headers = {
      "content-type": "application/json",  token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),type
    );
    body != null && requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    return requestMessage;
  };

  UnBookmarkCompany = async () => {
    let companyProfileId: string = this.props.navigation.getParam("id");

    const requestBody = {
      id: companyProfileId,
    };
    const requestMessage = await this.generateApiRequestMessage(
      configJSON.unBookmarkApiEndPoint+'?locale='+this.state.language,
      configJSON.apiUpdateUserType,
      requestBody
    );
    this.unBookmarkApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBookmarkList = async () => {
    const requestMessage = await this.generateApiRequestMessage(
      configJSON.bookmarkListEndPoint, configJSON.methodTypeGet,
    );

    this.getBookmarkListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  recieveCreateTitle = (message: Message) => {
    const [apiRequestCallId, responseJson] = this.getResponseVariablesData(message);
    if (this.createListApiCallID === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.setState(
          {
            bookmarkCreate: false,
          },
          () => this.getBookmarkList()
        );
      } else if (responseJson && responseJson.errors) {
        toast.error(responseJson.errors[0], getToastPosition());
      }
    }
  };

  bookmarkCatalogue = async (listID: number) => {
    let companyProfileId: string = this.props.navigation.getParam("id");
    const requestBody = {
      catalogue_list: {
        listing_id : companyProfileId,
        catalogue_list_id: listID,
      },
    };
    const requestMessage = await this.generateApiRequestMessage(
      configJSON.bookmarkEndPoint+'?locale='+this.state.language,
      configJSON.methodTypePut,
      requestBody
    );
    this.bookmarkApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  closeBookmark = () => {
    this.setState({
      bookmarkModal: false,
    });
  };

  catalogueReport = async (id: string) => {
    let companyProfileId: string = this.props.navigation.getParam("id");
    const requestBody = {
      user_archieved_catalogues: {
        report_id: id,
        is_reported: "true",
        listing_id: companyProfileId,
        is_archieved:"false",
      },
    };
    const requestMessage = await this.generateApiRequestMessage(
      `${configJSON.reportApiEndPoint}?locale=${this.state.language}`,
      configJSON.methodTypePost,
      requestBody
    );
    this.reportCatalogApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleBookmarkCreate = () => {
    this.setState({
      bookmarkCreate: !this.state.bookmarkCreate,
    });
  };

  handleFlage = () => {
    this.setState({
      flagHandle: !this.state.flagHandle,
    });
  };

  handleListSubmit = async (values: {
    listTitle: string;
  }) => {
    const requestBody = {
      catalogue_list: {
        title: values.listTitle,
      },
    };
    const requestMessage = await this.generateApiRequestMessage(
      configJSON.bookmarkListEndPoint,
      configJSON.methodTypePost,
      requestBody
    );
    this.createListApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFlagCloseModal = () => {
    this.setState({
      flagModalStatus: false,
    });
  };

  handleFlagModal = () => {
    this.getFlagReportList();
  };

  getFlagReportList = async () => {
    const requestMessage = await this.generateApiRequestMessage(
      `${configJSON.flagReportEndPoint}?locale=${this.state.language}`,
      configJSON.methodTypeGet,
    );
    this.getFlagReportApiListCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  flagArchiveModal = () => {
    this.setState({
      flagArchiveModalState: !this.state.flagArchiveModalState,
    });
  };

  archiveHandle = async () => {
    let companyProfileId: string = this.props.navigation.getParam("id");
    const requestBody = {
      user_archieved_catalogues: {
        is_archieved: true,
        listing_id: companyProfileId,
      },
    };
    const requestMessage = await this.generateApiRequestMessage(
      configJSON.reportApiEndPoint,
      configJSON.methodTypePost,
      requestBody
    );
    this.archiveCatalogApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToCategory = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
  }

  recieveArchive = (message: Message) => {
    const [apiRequestCallId, responseJson] = this.getResponseVariablesData(message);
    if (this.archiveCatalogApiCallID === apiRequestCallId) {
      if (responseJson.message) {
        toast.success(responseJson.message, getToastPosition());
        this.flagArchiveModal();
        this.getDetailsRequest()
        this.setState({flagHandle:false})
        this.navigateToCategory()
      }
    }
  };

  receiveFlagReportList = (message: Message) => {
    const [apiRequestCallId, responseJson] = this.getResponseVariablesData(message);
    if (this.getFlagReportApiListCallID === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.setState({
          flagReportList: responseJson.data,
          flagModalStatus: true,
        });
      } else {
        this.setState({
          flagModalStatus: !this.state.flagModalStatus,
        });
      }
    }
  };

  receiveTerminate = (message: Message) => {
    const [apiRequestCallId, responseJson] = this.getResponseVariablesData(message);
    if(this.terminateAccessId === apiRequestCallId) {
      toast.success(responseJson.message, getToastPosition());
      this.setState({isDisplayCancelConfirmationModal: false, isCancelSucces: true});
    }
  }
  recieveReport = (message: Message) => {
    const [apiRequestCallId, responseJson] = this.getResponseVariablesData(message);
    if (this.reportCatalogApiCallID === apiRequestCallId) {
      if (responseJson.message) {
        toast.success(responseJson.message, getToastPosition());
        this.handleFlagCloseModal();
        this.getDetailsRequest()
        this.setState({flagHandle:false})
        this.navigateToCategory();
      }
    }
  };
    // Customizable Area End
}
