import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import * as React from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData , removeStorageData} from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
export const configJSON = require("./config");
export const forgetJSON = require("../../forgot-password/src/config");
import {FormikProps} from "formik";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { getToastPosition } from "../../../components/src/Helper";

export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  classes: { [key: string]: string };
}

export interface FilledDataTypes {
  firstName: string,
  lastName: string,
  email: string,
  countryCode: string,
  phoneNumber: number | null,
  password: string,
  confirmPassword: string,
  acceptCheck: boolean
}

export interface CountryData {
  flags: {
    png: string
  },
  name: {
    common: string
  },
  idd: {
    root: string,
    suffixes: [string],
  },
  length: {
    min: number,
    max: number
  }
}
export interface S {
  isHovering: boolean;
  firstName: string;
  lastName: string;
  email: string;
  checkedFinal: boolean,
  phoneNumber: any;
  firstNameErrors: boolean;
  lastNameEroors: boolean;
  emailErrors: boolean;
  phoneErrors: boolean;
  emailErrorsString: any;
  phoneNumberString: any;
  selectedCountryCode: string;
  apiCallCountry: any;
  submitNavigate: boolean;
  isModal: boolean;
  countryCode: string;
  countryFlag: boolean;
  userType: string;
  postFileApi: any;
  password: string;
  validationEmail: string;
  validationEmailShow: boolean;
  validationPhone: string;
  iconHover: boolean;
  countrySelectionModalOpen: boolean;
  inputValue: string;
  resultval: any;
  resultValCopy: any;
  countryItemObject: CountryData;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  modalOpen: boolean;
  emailFocused: boolean;
  passwordFocused: boolean;
  confirmPasswordFocused: boolean;
  emailCodeFocused: boolean;
  phoneCodeFocused: boolean;
  emailRegisteredAlready: boolean;
  emailErrorRes: string;
  phoneNumberRegisteredAlready: boolean;
  urlToken: string;
  urlEmail: string;
  verificationEmailError: string;
  registerEmail: string;
  remainingTime: any;
  timer: any,
  isTimeOver: boolean,
  mobileRemainingTime: any,
  mobileTimer: any,
  mobileIsTimeOver: boolean,
  initialData: FilledDataTypes;
  passwordInfo: boolean;
  isDisabled: boolean;
  PasswordType:string;
  language:string;
  confirmPassword:string;
  anyError: string;
  touched: {
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    phoneNumber: boolean;
    password: boolean;
    confirmPassword: boolean;
    checkedFinal: boolean;
  };
  errors: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    checkedFinal: string;
  };
  signupPageText :{
    firstNameValidationsRequired:string;
    firstNameValidationsMatches:string;
    firstNameValidationMin :string;
    firstNameValidationMax:string;
    lastNameValidationsRequired:string;
    emailIdValidation:string;
    emailIdValidationRequired : string;
    phonenumberIsRequired :string;
    passwordRewuired : string;
    confirmPasswordRequired:string;
    passwordmissMatch:string;
    acceptTermsAndConditions:string;
    signUpText:string;
    firstNamelabel :string;
    lastNameLabel:string;
    emailLabel:string;
    phoneNumberLabel:string;
    passwordLabel:string;
    passwordStrengthlabel:string;
    confirmPasswordLabel:string;
    agreeToThe:string;
    optionsListOne:string;
    optionsListTwo : string;
    optionsListThree:string;
    optionsListFour:string;
    verification:string;
    pleaseEnterVerificationText:string;
    EnterCodeText:string;
    timeRemaingText:string;
    didNotReceivedCodeText:string;
    resendCodeText:string;
    phoneNumberVerificationText:string;
    verifyText :string;
    successText:string;
    verificationSuccessfulText:string;
    proceedText:string;
    nextText:string;
    sText:string;
    phoneNumberAlreadyExitsText:string;
    inValidPhoneNumberText: string;
    passwordWeak:string;
    passwordStorng:string;
    passwordmedium:string;
    termsCondtionsText:string;
    firstNamePlaceHolder:string;
    lastNamePlaceHolder:string;
    passwordLabelPlaceHolder:string;
    confirmPasswordLabelAr:string;
  }
}

export interface SS { }

export default class EmailAccountRegistrationBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  signUpApi = "";
  resendCodeApi = "";
  verifyApi = "";
  strongPasswordRegex: RegExp;
  emailregX: RegExp;
  mediumPasswordRegex: RegExp;
mediumAlpSpePasswordRegex:RegExp;
mediumNumSpePasswordRegex:RegExp;
  navigate: any;
  formPostApi: string = "";
  phoneNumberApi: any;
  formRef: React.RefObject<FormikProps<any>>;

  constructor(props: Props) {
    super(props);

    this.formRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      emailCodeFocused: false,
      checkedFinal: false,
      phoneCodeFocused: false,
      emailFocused: false,
      passwordFocused: false,
      confirmPasswordFocused: false,
      isHovering: false,
      firstName: "",
      lastName: "",
      passwordInfo: false,
      emailErrorRes: "",
      emailRegisteredAlready: false,
      phoneNumberRegisteredAlready: false,
      email: "",
      phoneNumber: "",
      firstNameErrors: false,
      lastNameEroors: false,
      emailErrors: false,
      phoneErrors: false,
      emailErrorsString: null,
      phoneNumberString: null,
      selectedCountryCode: "",
      apiCallCountry: [],
      submitNavigate: false,
      userType: "buyer",
      isModal: false,
      countryCode: "+966",
      countryFlag: false,
      postFileApi: {},
      password: "",
      validationEmail: "",
      validationEmailShow: false,
      validationPhone: "",
      iconHover: false,
      countrySelectionModalOpen: false,
      inputValue: "",
      resultval: forgetJSON.countryFlags,
      resultValCopy: forgetJSON.countryFlags,
      isDisabled: false,
      confirmPassword:"",
      anyError: "",
      touched: {
        firstName: false,
        lastName: false,
        email: false,
        phoneNumber: false,
        password: false,
        confirmPassword: false,
        checkedFinal: false
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        checkedFinal: ''
      },
      initialData: {
        firstName: "",
        lastName: "",
        "email": "",
        "phoneNumber": null,
        countryCode: "",
        password: "",
        confirmPassword: "",
        acceptCheck: false
      },
      countryItemObject: {
        flags: {
          png: ""
        },
        name: {
          common: ""
        },
        idd: {
          root: "",
          suffixes: [""],
        },
        length: {
          min: 8,
          max: 9
        }
      },
      enablePasswordField: false,
      enableConfirmPasswordField: false,
      modalOpen: false,
      urlToken: "",
      urlEmail: "",
      verificationEmailError: "",
      registerEmail: "",
      remainingTime: '',
      timer: null,
      isTimeOver: false,
      mobileRemainingTime: '',
      mobileTimer: null,
      mobileIsTimeOver: false,
      PasswordType:"",
      language:'en',
      signupPageText:{
        firstNameValidationsRequired: configJSON.firstNameValidationsRequired,
        firstNameValidationsMatches: configJSON.firstNameValidationsMatches,
        firstNameValidationMin : configJSON.firstNameValidationMin,
        firstNameValidationMax: configJSON.firstNameValidationMax,
        lastNameValidationsRequired:configJSON.lastNameValidationsRequired,
        emailIdValidation:configJSON.emailIdValidation,
        emailIdValidationRequired : configJSON.emailIdValidationRequired,
        phonenumberIsRequired :configJSON.phonenumberIsRequired,
        passwordRewuired : configJSON.passwordRewuired,
        confirmPasswordRequired:configJSON.confirmPasswordRequired,
        passwordmissMatch:configJSON.passwordmissMatch,
        acceptTermsAndConditions:configJSON.acceptTermsAndConditions,
        signUpText: configJSON.btnTextSignUp,
        firstNamelabel :configJSON.firstNamelabel,
        lastNameLabel:configJSON.lastNameLabel,
        emailLabel:configJSON.emailLabel,
        phoneNumberLabel:configJSON.phoneNumberLabel,
        passwordLabel:configJSON.passwordLabel,
        passwordStrengthlabel:configJSON.passwordStrengthlabel,
        confirmPasswordLabel:configJSON.confirmPasswordLabel,
        agreeToThe:configJSON.agreeToThe,
        optionsListOne:configJSON.optionsListOne,
        optionsListTwo : configJSON.optionsListTwo,
        optionsListThree:configJSON.optionsListThree,
        optionsListFour:configJSON.optionsListFour,
        verification:configJSON.verification,
        pleaseEnterVerificationText:configJSON.pleaseEnterVerificationText,
        EnterCodeText:configJSON.EnterCodeText,
        timeRemaingText:configJSON.timeRemaingText,
        didNotReceivedCodeText:configJSON.didNotReceivedCodeText,
        resendCodeText:configJSON.resendCodeText,
        phoneNumberVerificationText:configJSON.phoneNumberVerificationText,
        verifyText :configJSON.verifyText,
        successText:configJSON.successText,
        verificationSuccessfulText:configJSON.verificationSuccessfulText,
        proceedText:configJSON.proceedText,
        nextText:configJSON.nextText,
        sText:configJSON.sText,
        phoneNumberAlreadyExitsText:configJSON.phoneNumberAlreadyExitsText,
        inValidPhoneNumberText:configJSON.inValidPhoneNumberText,
        passwordWeak:configJSON.passwordWeakText,
        passwordStorng:configJSON.passwordStorngText,
        passwordmedium:configJSON.passwordMediumText,
        termsCondtionsText:configJSON.termsCondtionsText,
        firstNamePlaceHolder: configJSON.firstNamePlaceHolder,
        lastNamePlaceHolder:configJSON.lastNamePlaceHolder,
        passwordLabelPlaceHolder: configJSON.passwordLabelPlaceHolder,
        confirmPasswordLabelAr: configJSON.confirmPasswordLabelAr
      }
    };
    this.emailregX = new RegExp(
      /^[a-zA-Z\d._-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,4}$/);

    this.strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    this.mediumPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    this.mediumAlpSpePasswordRegex=/^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+\-={}\[\]:;'",.<>?/\\|`~]).+$/;
    this.mediumNumSpePasswordRegex=/^(?=.*[!@#$%^&*()_+\-={}\[\]:;'",.<>?/\\|`~])(?=.*\d).+$/;
  }
  async componentDidMount() {
    super.componentDidMount();
    this.startTimer();
    this.MobileStartTimer();
    this.apiPhoneNumber();
    this.getLang();
    const urlParams = new global.URLSearchParams(window.location.search);
    const tokenValue = urlParams.get('token') || "";
    const emailValue = urlParams.get('email') || "";
    const token = await getStorageData("token");
    this.setState({ urlToken: token, urlEmail: emailValue });
    const getTimer = await getStorageData("timer")
    const getMbileTimer = await getStorageData("mobileTimer")
    this.setState({ remainingTime: getTimer || 300  });
    this.setState({ mobileRemainingTime: getMbileTimer || 300  });
    setTimeout(() => {
      this.handleRegisterAccept()
    }, 1000)

  }
  async componentWillUnmount() {
    super.componentWillUnmount();
    const urlParams = new global.URLSearchParams(window.location.search);
    const tokenValue = urlParams.get('token') || "";
    const emailValue = urlParams.get('email') || "";
    this.clearTimer();
    this.setState({ urlToken: tokenValue, urlEmail: emailValue});
    removeStorageData("toBeRegister");
  }

  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ language: lang },  () => this.applyTranslation())
    }
    this.setState({ language: 'en' })
  }

  applyTranslation = async () => {
    let translatedObj : any = await TranslateText(this.state.signupPageText, this.state.language, 'en')
      this.setState({ signupPageText: translatedObj });
  }




  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)

    );
    if (this.resendCodeApi === apiRequestCallId) {
      this.receiveResendCode(responseJson);
    }
    else if (this.verifyApi === apiRequestCallId) {
      this.receiveVerify(responseJson);
    }
    else if (responseJson && responseJson.errors) {
      responseJson.errors.forEach((error: any) => {
        if (error.email) {
          this.setState({ emailRegisteredAlready: true, emailErrorRes: error.email });
        }
        if (error.full_phone_number) {
          this.setState({ phoneNumberRegisteredAlready: true });
          this.setState({ phoneErrors: true });
        } else {
          const firstErrorMessage = responseJson?.errors?.[0] && !responseJson.errors[0].hasOwnProperty('token')
  ? Object.values(responseJson.errors[0])[0]
            : "";
          this.setState({ anyError: firstErrorMessage as string })
        }
      })
    }
    else if (responseJson && responseJson.data) {
      await setStorageData("token", responseJson.meta.token);
      this.handleSuccess(responseJson.meta.token);
      await removeStorageData('timer');
      await removeStorageData('mobileTimer');

    }
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }

  handleClickShowPassword = () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField })
  }

  handleClickShowConfirmPassword = () => {
    this.setState({ enableConfirmPasswordField: !this.state.enableConfirmPasswordField })
  }


  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }

  
  handleInputCHange = (setFieldValue: any, event: any) => {
    const {name, value} = event.target;
    if(name==='email'){
      this.setState({emailRegisteredAlready:false})
    }
    if(name === "firstName" || name ==="lastName"){
      const rejex = /^[a-zA-Z\s]*$/;
      if(!rejex.test(value)){
        return
      }else{
        let obj:any  = {}
        obj[name] = value;
        this.setState(obj, () => {
          setFieldValue(name, value);
        });
      }
    }else{
      setFieldValue(name, value);
    }

  };

  handleCheckBoxChange = (setFieldValue: any, event: any) => {
    this.setState({ checkedFinal: !this.state.checkedFinal })
    setFieldValue(event.target.name, event.target.checked);
  };

  flagPhoneSetting = () => {
    let preFixCode = "";
    const { phoneNumber, countryItemObject } = this.state;
    if (countryItemObject.idd.root) {
      preFixCode = countryItemObject.idd.root + countryItemObject.idd.suffixes[0]
    } else {
      preFixCode = forgetJSON.defaultCountryCode
    }
    this.setState({countryCode: preFixCode });
    return preFixCode + phoneNumber;
  }


  handleFormSubmit = async() => {
    this.setState({ registerEmail: this.state.email })
    const headers = {
      "Content-Type": "application/json",
      "locale":this.state.language
    }
    const body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "first_name": this.state.firstName,
          "last_name": this.state.lastName,
          "email": this.state.email,
          "password": this.state.password,
          "password_confirmation": this.state.confirmPassword,
          "full_phone_number": this.flagPhoneSetting(),
          "profile_percent":20
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts?locale="+this.state.language
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    await setStorageData("timer", 300);
    await setStorageData("mobileTimer", 300);
  }
  handleSuccess=(token: string)=>{
    removeStorageData("toBeRegister");
    window.location.href = `/EmailAccountVerification?token=${token}&email=${this.state.registerEmail}`
  }
  handleBackToHomePage = () => {
    this.props.navigation.navigate("LandingPage",{});
  };

  handleLoginNav = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock",{});
  };

  handleVerificationPageSubmit = () => {
    window.location.href = "/Tutorials?from=signup"
  }

  handlePhoneNumberError = ()  => {
    const re = /^[0-9\b]+$/;
    const {phoneNumber, countryItemObject} = this.state;
    if (
      phoneNumber === "" ||
      (re.test(phoneNumber) && phoneNumber.length >= countryItemObject.length.min && phoneNumber.length <= countryItemObject.length.max)
    ) {
      this.setState({ phoneErrors: false });
    } else {
      this.setState({
        phoneErrors: true,
        phoneNumberString: configJSON.phoneNumberErrorsInvalid,
      });
    }
  }
  handleChangePhoneNumber = (setFieldValue: any, event: any) => {
    const rejex = /^[0-9\b]+$/;
    const {value} = event.target;
    if(value !=="" && !rejex.test(value)){
      return
    }else{
      this.setState({ phoneNumber: value }, ()=> {
        setFieldValue("phoneNumber", value);
        this.handlePhoneNumberError();
      });
    }
  };
handleChangePassword=(setFieldValue: any, event: any)=>{
  const {value}=event.target;
  if(value !== "undefined"){
    this.setState({password:value},()=>{
      setFieldValue("password",value);
    })
    if(this.strongPasswordRegex.test(value)){
    this.setState({PasswordType:"Strong"})
    }else if(this.mediumPasswordRegex.test(value) || this.mediumAlpSpePasswordRegex.test(value) || this.mediumNumSpePasswordRegex.test(value)){
    this.setState({PasswordType:"Medium"})
      }else{
    this.setState({PasswordType:"Weak"})
      }
    }
}
  handleOpen = () => {
    this.setState({ countrySelectionModalOpen: true });
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiPhoneNumber = async () => {
    this.phoneNumberApi = await this.apiCall({
      contentType: configJSON.apiCountrySelectorContentType,
      method: configJSON.apiCountrySelectorMethod,
      endPoint: configJSON.apiCountrySelectorEndPoint
    });
  }

  passwordInfoModal = () => {
    this.setState({ passwordInfo: !this.state.passwordInfo });
  }

  handleClose = () => {
    this.setState({ countrySelectionModalOpen: false });
  };

  handleChangeCountry = (value: any) => {
    this.setState({ inputValue: value });
    this.fetchCountryCodeData(value);

  };
  fetchCountryCodeData = (value: any) => {
    let backupStateOfResultVal = this.state.resultValCopy
    if (value) {
      const startwith = this.state.resultValCopy.filter((user: any) => {
        return (
          (user.name.common.toLowerCase().startsWith(value.toLowerCase())) || (user.idd.root.toLowerCase().includes(value))

        );
      });
      const endwith = this.state.resultValCopy.filter((user: any) => {
        return (
          (user.name.common.toLowerCase().includes(value.toLowerCase())) || (user.idd.root.toLowerCase().includes(value))

        );
      });
      const result = [...(new Set([...startwith, ...endwith]))];
      if (result.length > 0) {
        this.setState({ resultval: result })
      } else {
        this.setState({ resultval: [] })
      }
    }
    else {
      this.setState({ resultval: backupStateOfResultVal })
    }
  };

  onClickParticularCoutnry = (countryItem : any) => {
    this.setState({
      countryItemObject:countryItem,
      countrySelectionModalOpen:false,
      countryFlag:true,
    },() => {this.handlePhoneNumberError()})
  }


  handleRegisterAccept = async() => {
    let initialDataRecord = await getStorageData("toBeRegister");

    if(initialDataRecord){
      initialDataRecord = JSON.parse(initialDataRecord);

      let countryCodeObj = this.state.resultValCopy.filter((item: any) => {
        let countryCode =  item.idd.root + item.idd.suffixes[0];
        return (countryCode === initialDataRecord.countryCode);
      })

      this.onClickParticularCoutnry(countryCodeObj[0]);
      this.handleInputChange({target: {value: initialDataRecord.password, name: "password"}})

      this.setState({
        firstName: initialDataRecord.firstName,
        lastName: initialDataRecord.lastName,
        phoneNumber: initialDataRecord.phoneNumber,
        countryCode: initialDataRecord.countryCode,
        email: initialDataRecord.email,
        password: initialDataRecord.password,
        checkedFinal: initialDataRecord.checkedFinal,
        confirmPassword: initialDataRecord.confirmPassword,
        isDisabled: !initialDataRecord.acceptCheck,
        initialData: initialDataRecord
      }, () =>  {
        this.formRef.current?.setValues(initialDataRecord);
        setTimeout(()=>{
          this.formRef.current?.setTouched(initialDataRecord, true);
        }, 500);
        removeStorageData("toBeRegister")})
    }
  }




  handleRegisterData = () => {

    this.formRef.current?.setFieldValue("countryCode", this.state.countryCode);
    this.formRef.current?.setFieldValue("password", this.state.password);
    this.formRef.current?.setFieldValue("email", this.state.email);
    this.formRef.current?.setFieldValue("confirmPassword", this.state.confirmPassword);
    this.formRef.current?.setFieldValue("phoneNumber", this.state.phoneNumber);
    this.formRef.current?.setFieldValue("checkedFinal", this.state.checkedFinal);
    this.formRef.current?.setFieldValue("firstName", this.state.firstName);
    this.formRef.current?.setFieldValue("lastName", this.state.lastName);
    setTimeout(() => {

      setStorageData("toBeRegister", JSON.stringify({...this.formRef.current?.values, countryCode: this.state.countryItemObject.idd.root + this.state.countryItemObject.idd.suffixes[0]}));

      window.location.href = "/TermsConditionsSignUp"
    }, 1000);

  }
  handleResendCode = () => {
    this.setState({ verificationEmailError: "" })
    const headers = {
      "Content-Type": configJSON.apiCountrySelectorContentType,
      token: this.state.urlToken,
      "locale":this.state.language
    }
    const body = {
      email: this.state.urlEmail
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendCodeApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendCodeAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleVerify = (values: { email: string, phone: string }) => {
    this.setState({ verificationEmailError: "" })
    const headers = {
      "Content-Type": configJSON.apiCountrySelectorContentType,
      token: this.state.urlToken,
      "locale":this.state.language
    }

    const body = {
      "data": {
        "attributes": {
          email_otp: values.email,
          mobile_otp: values.phone,
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.verifyAPiEndPoint}?locale=${this.state.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveResendCode = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      if (typeof responseJson.errors === "string") {
        this.setState({ verificationEmailError: responseJson.errors });
      } else {
        toast.error(responseJson.errors[0].token, getToastPosition());
      }
    } else if (responseJson && responseJson.message) {
      toast.success(responseJson.message, getToastPosition());
    }
  }

  receiveVerify = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      if (typeof responseJson.errors === "string") {
        this.setState({ verificationEmailError: responseJson.errors });
      } else {
        toast.error(responseJson.errors[0].token, getToastPosition());
      }
    } else if (responseJson && responseJson.data) {
      this.toggleModal()
      this.clearTimer()
      this.MobileClearTimer()
      removeStorageData('timer');
      removeStorageData('mobileTimer');
    }
  }
  saveRemainingTimeToLocalStorage = async() => {
    await setStorageData("timer", this.state.remainingTime.toString());
  };
  startTimer = () => {
    this.clearTimer();
    const timer = setInterval(this.tick, 1000);
    this.setState({ timer });
  };
  clearTimer = () => {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  };

  tick = () => {
    if (this.state.remainingTime > 0) {
      this.setState((prevState) => ({
        remainingTime: prevState.remainingTime - 1
      }),
        this.saveRemainingTimeToLocalStorage

      );
    } else {
      this.clearTimer();
      this.setState({ isTimeOver: true });
      this.saveRemainingTimeToLocalStorage();


    }
  };








  MobileStartTimer = () => {
    this.MobileClearTimer();
    const mobileTimer = setInterval(this.MobileTick, 1000);
    this.setState({ mobileTimer });
  };
  MobileClearTimer = () => {
    if (this.state.mobileTimer) {
      clearInterval(this.state.mobileTimer);
    }
  };
  mobileSaveRemainingTimeToLocalStorage = async () => {
    await setStorageData("mobileTimer", this.state.remainingTime.toString());
  };


  MobileTick = () => {
    if (this.state.mobileRemainingTime > 0) {
      this.setState((prevState) => ({
        mobileRemainingTime: prevState.mobileRemainingTime - 1,
      }),
        this.mobileSaveRemainingTimeToLocalStorage
      );
    } else {
      this.MobileClearTimer();
      this.setState({ mobileIsTimeOver: true });
      this.mobileSaveRemainingTimeToLocalStorage()
    }
  }
  handleResendOTP = () => {
    this.setState({ isTimeOver: false, remainingTime: 300 });
    this.startTimer();
    this.handleResendCode();
  };
  MobileHandleResendOTP = () => {
    this.setState({ mobileIsTimeOver: false, mobileRemainingTime: 300 });
    this.MobileStartTimer();
  };

  returnPasswordType = () => {
    const lang = this.state.language;
    if(this.state.PasswordType === "Strong")
      {
        return lang === "ar" ? "قوي" : this.state.signupPageText.passwordStorng
      }else if(this.state.PasswordType === "Medium")
      {
        return lang === "ar" ? "وسط" : this.state.signupPageText.passwordmedium
      }else{
        return lang === "ar" ? "ضعيف" : this.state.signupPageText.passwordWeak
      }
  }

  handleInputChange = (event: {target: {name: string, value: string}}) => {
    const { name, value } = event.target;
    if(name === "password"){
    if(this.strongPasswordRegex.test(value)){
      this.setState({PasswordType:"Strong"})
      }else if(this.mediumPasswordRegex.test(value) || this.mediumAlpSpePasswordRegex.test(value) || this.mediumNumSpePasswordRegex.test(value)){
      this.setState({PasswordType:"Medium"})
        }else{
      this.setState({PasswordType:"Weak"})
        }
      }
    if(name ==="phoneNumber"){
      this.setState({
        touched: { ...this.state.touched, phoneNumber: true },
      });
    }
    if(name ==="email"){
      this.setState({emailRegisteredAlready:false})
    }
    if(name ==="phoneNumber"){
      this.setState({phoneNumberRegisteredAlready:false, phoneErrors: false})
    }
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>, () => {
      this.validateField(name);
    });
  }

  validateField = (field: string) => {
    let errorMessage = '';
    switch (field) {
      case 'firstName':
        errorMessage = this.validateFirstName();
        break;
      case 'lastName':
        errorMessage = this.validateLastName();
        break;
      case 'email':
        errorMessage = this.validateEmail();
        break;
      case 'phoneNumber':
        errorMessage = this.validatePhoneNumber();
        break;
      case 'password':
        errorMessage = this.validatePassword();
        break;
      case 'confirmPassword':
        errorMessage = this.validateConfirmPassword();
        break;
      case 'checkedFinal':
        errorMessage = this.validateCheckedFinal();
        break;
      default:
        break;
    }
    this.setState({
      errors: { ...this.state.errors, [field]: errorMessage },
    });
  }

  validateFirstName = () => {
    const { firstName } = this.state;
    if (!firstName) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.firstNameValidationsRequired, "الاسم الأول مطلوب");
    if (firstName.length < 3) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.firstNameValidationMin, "الرجاء ادخال 3 حروف على الأقل");
    if (firstName.length > 20) return this.state.signupPageText.firstNameValidationMax;
    const nameRegex = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFFa-zA-Z ]+$/;
    if (!nameRegex.test(firstName)) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.firstNameValidationsMatches, "الرجاء استخدام الحروف الأبجدية فقط");
    return '';
  }
  
  validateLastName = () => {
    const { lastName } = this.state;
    const lastRegex = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFFa-zA-Z ]+$/;
    if (!lastName) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.lastNameValidationsRequired, "اسم العائلة مطلوب");
    if (lastName.length < 3) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.firstNameValidationMin, "الرجاء ادخال 3 حروف على الأقل");
    if (lastName.length > 20) return this.state.signupPageText.firstNameValidationMax;
    if (!lastRegex.test(lastName)) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.firstNameValidationsMatches, "الرجاء استخدام الحروف الأبجدية فقط")
    return '';
  }

  validateEmail = () => {
    const { email } = this.state;
    if (!email) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.emailIdValidationRequired, "عنوان الإيميل مطلوب");
    
    if(!this.emailregX.test(email)) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.emailIdValidation, "البريد الإلكتروني غير صالح")
    return '';
  }

  handledBlurFirst = (field: keyof S) => () => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
    this.validateField(field);
  }

  validatePhoneNumber = () => {
    const { phoneNumber } = this.state;
    if (!phoneNumber) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.phonenumberIsRequired, "رقم الهاتف مطلوب");
    const phoneRegex = /^\d{8,10}$/;
    if(!phoneRegex.test(phoneNumber)) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.inValidPhoneNumberText, "رقم الهاتف غير صحيح");
    return ""
  }

  validatePassword = () => {
    const { password } = this.state;
    if (!password) return this.state.signupPageText.passwordRewuired
    return '';
  }

  validateConfirmPassword = () => {
    const { password, confirmPassword } = this.state
    if (!confirmPassword) return this.getSpecifiedLangString(this.state.language, this.state.signupPageText.confirmPasswordRequired, "تأكيد كلمة المرور مطلوب");
    if (password !== confirmPassword) return this.state.signupPageText.passwordmissMatch
    return '';
  }

  validateCheckedFinal = () => {
    const { checkedFinal } = this.state;
    if (!checkedFinal) return this.state.signupPageText.acceptTermsAndConditions
    return '';
  }

  handleCheckBoxChangeBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {  checked } = event.target;
    this.setState({ checkedFinal: checked,touched: { ...this.state.touched, checkedFinal: true }}, ()=>{
      this.validateField("checkedFinal");
    });
    
  }

  areAllErrorsEmpty = (): boolean => {
    return Object.values(this.state.errors).every(error => error === '');
  };
  

  conditionOr = (condtion1:boolean | string, condtion2: string, value1:string, value2:string) =>{
    if(condtion1 || condtion2){
      return value1
    }else{
      return value2
    }
  }

  conditionAndCheck = (condition :boolean, value1:string,value2:string) =>{
    if(condition){
      return value1
    }else{
      return value2
    }
  }

}

// Customizable Area End
